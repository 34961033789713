import React from "react";
import { DateTimeText, DateTimeTextProps } from "./DateTimeText";
import { DateTime } from "luxon";

type DateTextProps = Omit<DateTimeTextProps, "dateTime" | "labelFormat" | "showTimeZoneNameInLabel"> & {
  date: DateTime;
  labelFormat?: string;
};

export function DateText(props: DateTextProps) {
  const { date, labelFormat, ...rest } = props;

  return <DateTimeText dateTime={date} labelFormat={labelFormat ?? "d MMM yyyy"} {...rest} />;
}
