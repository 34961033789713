import { SvgIcon } from "@mui/material";
import React from "react";
import { iconStyles } from "./iconStyles";

export function FeedbackIcon() {
  return (
    <SvgIcon sx={iconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8.49589V19.4858C3 20.36 3.625 20.9845 4.5 20.9845H7.5C8.3125 20.9845 9 21.6713 9 22.4831V23.732L12.625 21.2342C12.875 21.1094 13.1875 20.9845 13.5 20.9845H21.5C22.3125 20.9845 23 20.36 23 19.4858V8.49589C23 7.68413 22.3125 6.99726 21.5 6.99726H4.5C3.625 6.99726 3 7.68413 3 8.49589ZM4.5 4H21.5C23.9375 4 26 6.06062 26 8.49589V19.4858C26 21.9836 23.9375 23.9817 21.5 23.9817H13.9375L8.3125 27.7283C7.875 28.0405 7.25 28.103 6.75 27.8532C6.25 27.541 6 27.0414 6 26.4794V23.9817H4.5C2 23.9817 0 21.9836 0 19.4858V8.49589C0 6.06062 2 4 4.5 4ZM16 27.4785V25.9799H19V27.4785C19 28.3527 19.625 28.9772 20.5 28.9772H26.5C26.75 28.9772 27.0625 29.102 27.3125 29.2269L31 31.7246V30.4758C31 29.664 31.625 28.9772 32.5 28.9772H35.5C36.3125 28.9772 37 28.3527 37 27.4785V16.4886C37 15.6768 36.3125 14.99 35.5 14.99H28V11.9927H35.5C37.9375 11.9927 40 14.0533 40 16.4886V27.4785C40 29.9762 37.9375 31.9744 35.5 31.9744H34V34.4721C34 35.0341 33.6875 35.5337 33.1875 35.8459C32.6875 36.0956 32.125 36.0332 31.625 35.721L26 31.9744H20.5C18 31.9744 16 29.9762 16 27.4785Z" />
    </SvgIcon>
  );
}