import * as React from "react";
import { SvgIcon } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SxProps, Theme } from "@mui/system";

export type FontAwesomeSvgIconProps = {
  icon: IconDefinition;
  sx?: SxProps<Theme>;
};

// Adapted from full source code example at: https://mui.com/material-ui/icons/#font-awesome
export const FontAwesomeSvgIcon = React.forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>((props, ref) => {
  const { icon, sx } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} sx={sx}>
      {typeof svgPathData === "string" ? (
        <path d={svgPathData} />
      ) : (
        svgPathData.map((d: string, i: number) => <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
      )}
    </SvgIcon>
  );
});
