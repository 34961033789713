import React from "react";
import { SvgIcon } from "@mui/material";
import { iconStyles } from "./iconStyles";

export function SlackIcon() {
  return (
    <SvgIcon sx={iconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.875 23.75C11.875 25.3125 10.5 26.6875 8.9375 26.6875C7.3125 26.6875 6 25.3125 6 23.75C6 22.125 7.3125 20.8125 8.9375 20.8125H11.875V23.75ZM13.3125 23.75C13.3125 22.125 14.6875 20.8125 16.25 20.8125C17.875 20.8125 19.1875 22.125 19.1875 23.75V31.0625C19.1875 32.6875 17.875 34 16.25 34C14.6875 34 13.3125 32.6875 13.3125 31.0625V23.75ZM16.25 11.9375C14.6875 11.9375 13.3125 10.5625 13.3125 9C13.3125 7.375 14.6875 6 16.25 6C17.875 6 19.1875 7.375 19.1875 9V11.9375H16.25ZM16.25 13.375C17.875 13.375 19.1875 14.6875 19.1875 16.3125C19.1875 17.9375 17.875 19.25 16.25 19.25H8.9375C7.3125 19.25 6 17.9375 6 16.3125C6 14.6875 7.3125 13.375 8.9375 13.375H16.25ZM28.0625 16.3125C28.0625 14.6875 29.4375 13.375 31 13.375C32.625 13.375 34 14.6875 34 16.3125C34 17.9375 32.625 19.25 31 19.25H28.0625V16.3125ZM26.625 16.3125C26.625 17.9375 25.3125 19.25 23.6875 19.25C22.0625 19.25 20.75 17.9375 20.75 16.3125V9C20.75 7.375 22.0625 6 23.6875 6C25.3125 6 26.625 7.375 26.625 9V16.3125ZM23.6875 28.125C25.3125 28.125 26.625 29.5 26.625 31.0625C26.625 32.6875 25.3125 34 23.6875 34C22.0625 34 20.75 32.6875 20.75 31.0625V28.125H23.6875ZM23.6875 26.6875C22.0625 26.6875 20.75 25.3125 20.75 23.75C20.75 22.125 22.0625 20.8125 23.6875 20.8125H31C32.625 20.8125 34 22.125 34 23.75C34 25.3125 32.625 26.6875 31 26.6875H23.6875Z" />
    </SvgIcon>
  );
}



