// eslint-disable @typescript-eslint/no-explicit-any

import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { getOrParseDateTime } from "./getOrParseDateTime";

// We defined a couple of pre-test regex's to make sure we are only dealing with correctly formatted ISO 8601 dates
const dateTestRegex: RegExp = /^\d{4}-\d{2}-\d{2}/; // starts with YYYY-MM-DD
const timeTestRegex: RegExp = /^\d{2}:\d{2}:\d{2}$/; // equals hh:mm:ss

export function interceptAndDeserializeDateTimes(response: AxiosResponse): AxiosResponse {
  if (response.data === null || response.data === undefined) {
    return response;
  }

  //if the data is a raw string, try and parse that
  if (typeof response.data === "string") {
    const parsedDateTime = tryParseToDateTime(response.data);
    if (parsedDateTime) {
      response.data = parsedDateTime;
    }
  } else {
    transformDateTime(response.data);
  }
  return response;
}

function transformDateTime(obj: any): any {
  if (obj === null || obj === undefined) {
    return;
  }

  for (const key of Object.keys(obj)) {
    const value = obj[key];

    const parsedDateTime = tryParseToDateTime(value);
    if (parsedDateTime) {
      obj[key] = parsedDateTime;
    }
    //if this is an object, recurse in and transform its child fields
    else if (typeof value === "object") {
      transformDateTime(value);
    }
  }
}

function tryParseToDateTime(value?: any): DateTime | undefined {
  if (!value || typeof value !== "string") {
    return undefined;
  }

  //do a pre-test to make sure the string is in a desired format
  if (!dateTestRegex.test(value) && !timeTestRegex.test(value)) {
    return undefined;
  }

  //make sure we specify the zone as UTC as all responses coming from the server are in UTC
  const dateTime = getOrParseDateTime(value, { zone: "utc" });

  return dateTime.isValid ? dateTime : undefined;
}
