// Color reference: https://www.octopus.design/932c0f1a9/p/92366e-colours/b/19e43e/t/81556a
export const designSystemColors = {
  blue: {
    100: "#F2F8FD",
    200: "#CDE4F7",
    300: "#87BFEC",
    500: "#1A77CA",
    600: "#155EA0",
  },
  cyan: {
    200: "#c5e6ff",
    800: "#124164",
  },
  green: {
    200: "#B8E7D3",
    300: "#5ECD9E",
    500: "#00874D",
    700: "#00502E",
    800: "#00361F",
  },
  grey: {
    100: "#F7F7F7",
    200: "#E1E1E1",
    600: "#5C5C5C",
    800: "#2E2E2E",
  },
  navy: {
    900: "#111A23",
  },
  orange: {
    200: "#FFDABF",
    400: "#EA7325",
    600: "#A13C14",
    900: "#340B07",
  },
  red: {
    200: "#FFD8D8",
    300: "#FF9F9F",
    500: "#D63D3D",
    600: "#AA3030",
    700: "#802424",
    800: "#571818",
    900: "#310E0E",
  },
  slate: {
    200: "#DEE1E6",
    400: "#8A96A7",
  },
  yellow: {
    100: "#FFF7D9",
    600: "#745801",
  },
};
