import React from "react";
import { SvgIcon } from "@mui/material";
import { iconStyles } from "./iconStyles";

export function BookIcon() {
  return (
    <SvgIcon sx={iconStyles} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.5C6 6.5 8.4375 4 11.5 4H30.5C32.375 4 34 5.625 34 7.5V25.5C34 26.9375 33.125 28.125 32 28.6875V33H32.5C33.3125 33 34 33.6875 34 34.5C34 35.375 33.3125 36 32.5 36H11C8.1875 36 6 33.8125 6 31C6 30.875 6 30.6875 6 30.5V9.5ZM11 29C9.875 29 9 29.9375 9 31C9 32.125 9.875 33 11 33H29V29H11ZM9 26.4375C9.5625 26.1875 10.25 26 11 26H30.5C30.75 26 31 25.8125 31 25.5V7.5C31 7.25 30.75 7 30.5 7H11.5C10.0625 7 9 8.125 9 9.5V26.4375ZM15.5 11H26.5C27.3125 11 28 11.6875 28 12.5C28 13.375 27.3125 14 26.5 14H15.5C14.625 14 14 13.375 14 12.5C14 11.6875 14.625 11 15.5 11ZM15.5 16H26.5C27.3125 16 28 16.6875 28 17.5C28 18.375 27.3125 19 26.5 19H15.5C14.625 19 14 18.375 14 17.5C14 16.6875 14.625 16 15.5 16Z" />
    </SvgIcon>
  );
}
