import React from "react";
import { DateTimeText, DateTimeTextProps } from "./DateTimeText";
import { DateTime } from "luxon";

type TimeTextProps = Omit<DateTimeTextProps, "dateTime" | "labelFormat"> & {
  time: DateTime;
  labelFormat?: string;
};

export function TimeText(props: TimeTextProps) {
  const { time, labelFormat, tooltipFormat, ...rest } = props;

  return (
    <DateTimeText
      dateTime={time}
      labelFormat={labelFormat ?? "h:mm a"}
      tooltipFormat={tooltipFormat || "h:mm a"}
      {...rest}
    />
  );
}
