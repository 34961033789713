import { IconButton, IconButtonProps } from "@mui/material";
import { InfoIcon } from "../Icons/InfoIcon";
import React from "react";

const colors = {
  backgroundColor: "#CDE4F7",
  hover: {
    backgroundColor: "#87BFEC",
  },
  color: "#0F4778",
};

export const InfoButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        backgroundColor: colors.backgroundColor,
        "&:hover": {
          backgroundColor: colors.hover.backgroundColor,
        },
      }}
      color="primary"
    >
      <InfoIcon sx={{ color: colors.color }} />
    </IconButton>
  );
});
