import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";

type FormSelectProps = UseControllerProps & {
  label: string;
  labelId: string;
  selectItems: [value: string, name: string][];
  fullWidth?: boolean;
};

export function FormSelect(props: FormSelectProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      rules={props.rules}
      shouldUnregister={props.shouldUnregister}
      control={control}
      render={({ field: { ref, ...rest }, fieldState }) => (
        <FormControl fullWidth={props.fullWidth || false}>
          <InputLabel id={props.labelId} error={fieldState.invalid}>
            {props.label}
          </InputLabel>
          <Select {...rest} labelId={props.labelId} label={props.label} error={fieldState.invalid}>
            {props.selectItems.map(([key, value]) => (
              <MenuItem value={key} key={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {fieldState.invalid && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
