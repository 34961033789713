import type { LocaleOptions } from "luxon";
import { DateTime, Settings } from "luxon";
import React, { ReactNode, useMemo, useState } from "react";
import { ClickAwayListener, IconButton, SvgIcon, TypographyProps } from "@mui/material";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { getShortTimeZoneName } from "@cpt/shared";
import { InfoButton } from "../InfoButton/InfoButton";

export type DateTimeTextProps = {
  dateTime: DateTime;
  labelFormat: string;
  tooltipFormat?: string;
  localeOptions?: LocaleOptions;
  hideRelativeIndicator?: boolean;
} & TypographyProps;

type DisplayOption = { label: string; timeZoneName: string; dateTime: string; relative: "ahead" | "behind" | "equal" };

const colors = {
  tooltip: {
    color: "#515D70",
    header: {
      backgroundColor: "#F5F6F8",
    },
    borderColor: "#DEE1E6",
  },
};

export function DateTimeText(props: DateTimeTextProps) {
  const {
    dateTime,
    labelFormat,
    tooltipFormat,
    localeOptions: localeOptionsProp,
    hideRelativeIndicator,
    ...rest
  } = props;

  const theme = useTheme();

  const localeOptions: LocaleOptions = useMemo(() => {
    return (
      localeOptionsProp ||
      ({
        locale: Settings.defaultLocale,
        outputCalendar: Settings.defaultOutputCalendar,
        numberingSystem: Settings.defaultNumberingSystem,
      } as LocaleOptions)
    );
  }, [localeOptionsProp]);

  const label = useMemo(() => {
    return dateTime.toFormat(labelFormat, localeOptions);
  }, [dateTime, labelFormat, localeOptions]);

  const [localDateTime, localTimeZoneName] = useMemo(() => {
    const local = dateTime.toLocal();
    return [local, getShortTimeZoneName(local, localeOptions)];
  }, [dateTime, localeOptions]);

  const displayOptions: Array<DisplayOption> = useMemo(() => {
    const fmt = tooltipFormat || "h:mm a, d MMM yyyy";
    const utcDateTime = dateTime.toUTC();

    const isLocalAhead =
      localDateTime.day > utcDateTime.day ||
      localDateTime.month > utcDateTime.month ||
      localDateTime.year > utcDateTime.year;

    const isLocalBehind =
      localDateTime.day < utcDateTime.day ||
      localDateTime.month < utcDateTime.month ||
      localDateTime.year < utcDateTime.year;

    return [
      {
        label: "System",
        timeZoneName: "UTC",
        dateTime: utcDateTime.toFormat(fmt, localeOptions),
        relative: "equal",
      },
      {
        label: "Your computer",
        timeZoneName: localTimeZoneName,
        dateTime: localDateTime.toFormat(fmt, localeOptions),
        relative: isLocalAhead ? "ahead" : isLocalBehind ? "behind" : "equal",
      },
    ];
  }, [dateTime, tooltipFormat, localeOptions, localDateTime, localTimeZoneName]);

  const showRelativeIndicator = useMemo(() => {
    return !hideRelativeIndicator && displayOptions.some((opt) => opt.relative !== "equal");
  }, [hideRelativeIndicator, displayOptions]);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleShow = () => {
    setShowTooltip(!showTooltip);
  };

  const handleClose = () => {
    setShowTooltip(false);
  };

  const getRelativeIndicatorIcon = (option: DisplayOption): ReactNode | undefined => {
    switch (option.relative) {
      case "ahead":
        return <NorthIcon sx={{ height: "1rem", width: "1rem" }} titleAccess={"Next day"} />;
      case "behind":
        return <SouthIcon sx={{ height: "1rem", width: "1rem" }} titleAccess={"Previous day"} />;
      case "equal":
        return undefined;
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <Box component="span" display={"inline-flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography component="span" {...rest}>
          {label}
        </Typography>

        <Tooltip
          arrow
          placement={"bottom-end"}
          onClose={handleClose}
          open={showTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: theme.palette.common.white,
                maxWidth: "350px",
                boxShadow: 3,
                padding: 0,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.tooltip.borderColor,
              },
            },
            arrow: {
              sx: {
                color: colors.tooltip.header.backgroundColor,
                "&::before": {
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: colors.tooltip.borderColor,
                },
              },
            },
          }}
          title={
            <>
              <Box
                px={1}
                py={0.25}
                fontSize={"0.75rem"}
                fontWeight={"bold"}
                sx={{
                  backgroundColor: colors.tooltip.header.backgroundColor,
                  color: colors.tooltip.color,
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.tooltip.borderColor,
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
              >
                Time conversion
              </Box>
              <Stack spacing={0} direction={"column"}>
                {displayOptions.map((opt, idx) => {
                  return (
                    <Stack
                      spacing={0}
                      direction={"row"}
                      key={opt.label}
                      py={0.25}
                      px={1}
                      sx={{
                        //show the border at the top except on the first item
                        borderTopWidth: idx !== 0 ? 1 : 0,
                        borderTopStyle: "solid",
                        borderTopColor: colors.tooltip.borderColor,
                      }}
                    >
                      <Box flexGrow={1} mr={2}>
                        <Typography fontSize={"0.75rem"} color={colors.tooltip.color}>
                          <strong>{opt.label}</strong> ({opt.timeZoneName})
                        </Typography>
                      </Box>
                      <Box maxWidth={"9rem"} ml={2} textAlign="right">
                        <Typography fontSize={"0.75rem"} color={colors.tooltip.color}>
                          {opt.dateTime}
                        </Typography>
                      </Box>
                      {showRelativeIndicator && (
                        <Box width={"1rem"} height={"1rem"} sx={{ color: colors.tooltip.color }}>
                          {opt.relative !== "equal" && getRelativeIndicatorIcon(opt)}
                        </Box>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </>
          }
        >
          <InfoButton
            sx={{
              ml: 1,
              w: 2.5,
              h: 2.5,
              p: 0,
            }}
            onClick={handleShow}
          />
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
}
