//this is quite messy, but seems to be the most consistent way to retrieve the short/abbreviated Time Zone Name based on the users locale
import { DateTime, LocaleOptions } from "luxon";

export function getShortTimeZoneName(dateTime: DateTime, localeOptions: LocaleOptions): string {
  //frustratingly, this includes the date as well :shrug:
  const parts = dateTime.toLocaleParts({ timeZoneName: "short" });
  const timeZonePart = parts.find((x) => x.type === "timeZoneName");

  //if we haven't retrieved the timeZonePart for some reason, just fall back on the ZZZZ format string
  const timeZoneName = timeZonePart?.value ?? dateTime.toFormat("ZZZZ", localeOptions);

  //to make it consistent with the UTC string, we replace GMT+ with UTC+
  return timeZoneName.replace("GMT+", "UTC+");
}
